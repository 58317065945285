import { network as notify, report } from '@aw/lib'
import Axios from 'axios'
import resolve from '../config/api-proxy'


export function getCookie(name: string) {
    if (typeof window !== "undefined") {
        const cookie = document.cookie
            .split("; ")
            .find((item) => item.startsWith(`${name}=`));

        if (!cookie) {
            return null;
        }

        return decodeURIComponent(cookie.split("=")[1]);
    }
    return null
}
const axios = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    withXSRFToken: true,
})
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
const ignoreStatuses = [401, 423]

axios.interceptors.request.use(
    function (config) {
        config = Object.assign({}, config, { url: resolve(config.url) })

        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => response,
    error => {
        if (error?.ignore || ignoreStatuses.includes(error?.response?.status)) return Promise.reject(error)

        if (!error.response) {
            report(error)
            return Promise.reject(error)
        }

        if (error.response.status === 422) {
            notify(422, error.response.data.message || 'Validation error')
            return
        }

        if (error.response.status === 403) {
            notify(403, error.response.data.message || 'Access forbidden')
            return
        }

        report(error)

        return Promise.reject(error)
    }
)

export default axios
