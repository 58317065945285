import resolve from '@/config/api-proxy'
import { network as notify, report } from '@aw/lib'
import { _hasProperty, _isObjEmpty } from '@aw/utils'
import { AquaRequest } from 'aquarequest'
import React, { useCallback, useRef, useState } from 'react'
import { useDialog } from '@aw/dialog'

const initialState = {
    busy: false,
    hasError: false,
    statusCode: null,
    result: null,
    message: '',
    errors: {},
}

export type response<T> = {
    status: number
    data: T
    [other: string]: any
}

type hooks = {
    onBefore?: () => void
    onStart?: () => void
    onDownload?: () => void
    onUpload?: () => void
    onCancel?: () => void
    onStatusCode?: (code: number) => void
    onSuccess?: (response: response<unknown>) => void
    onError?: (response: response<unknown>) => void
    onFinish?: () => void
}
type method = 'get' | 'post' | 'put' | 'patch' | 'delete'
type config = { options?: {}; hooks?: hooks | {} }

export type formState = {
    code?: any
    busy: boolean
    hasError: boolean
    statusCode: string | number
    result: any
    message: string
    errors: { [key: string]: string }
    isValidationError: boolean
}

export type useFormResponse = {
    state: formState
    setFormError: (errors: { [field: string]: string } | string, value?: string) => void
    clearFormError: (...fields: string[]) => void
    resetStates: () => void
    cancel: () => void
    submit: (method: method, payload?: {}, config?: config) => void
    get: (payload?: {}, hooks?: hooks) => void
    post: (payload?: {}, hooks?: hooks) => void
    put: (payload?: {}, hooks?: hooks) => void
    patch: (payload?: {}, hooks?: hooks) => void
    _delete: (payload?: {}, hooks?: hooks) => void
}

// for herd in local
const csrfTokenfromenv = process.env.NEXT_PUBLIC_MANUAL_LOGIN_COOKIE ? { 'X-XSRF-TOKEN': localStorage.getItem('csrf') } : {}

export default function useForm(url: string): useFormResponse {
    const [state, setState] = useState<typeof initialState>({ ...initialState })
    const { show: openPasswdConfirmationDialog } = useDialog('PASSWORD_CONFIRMATION_DIALOG')
    const lastRequest = useRef<{
        url: string
        method: method
        payload?: object
        config?: config
    }>({
        url,
        method: 'get',
        payload: {},
    })

    const errorNotify = response => {
        if (response?.status === 401) {
            notify(401, 'not authenticated')
            return
        }
        if (response?.status === 423) {
            notify(423, 'Password confirmation required')
            return
        }
        if (response?.status === 422) {
            return
        }

        const msg = response?.status === 403 ? 'Access Forbidden' : response?.data?.message || 'something went wrong'

        console.log({ response })

        notify(response?.status || 'Error', msg)
        report(response)
    }

    const aquarequest = new AquaRequest(url.startsWith('http') ? url : `${process.env.NEXT_PUBLIC_BACKEND_URL}${resolve(url)}`)

    aquarequest
        .mergeRequestOptions({
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                ...csrfTokenfromenv
            },
        })
        .setRequestHooks({
            onBefore: () => setState({ ...initialState, busy: true }),

            onSuccess: response => {
                setState(currentState => ({
                    ...currentState,
                    hasError: Number(response.status) >= 300,
                    statusCode: response.status,
                    result: response.data,
                    message: response?.data?.message,
                    errors: {},
                }))
            },
            onError: async response => {
                let keyedErrors = {}

                if (response?.data?.errors) {
                    keyedErrors = {}

                    Object.entries(response.data.errors).forEach(([field, msg]) => (keyedErrors[field] = msg[0]))
                }

                setState(currentState => ({
                    ...currentState,
                    hasError: (response?.status && Number(response?.status) >= 300) || response instanceof Error,
                    statusCode: response?.status,
                    result: response?.data,
                    message: response?.data?.message,
                    errors: keyedErrors,
                }))

                if (response?.status === 423) {
                    openPasswdConfirmationDialog()
                }

                errorNotify(response)
            },
            onFinish: () => setState(currentState => ({ ...currentState, busy: false })),
        })

    const submit = (method: method = 'get', payload = {}, config: config = { options: {}, hooks: {} }) => {
        lastRequest.current = { url, method, payload, config }
        return aquarequest.submit(method, payload, config)
    }

    const get = (payload = {}, hooks: hooks = {}) => {
        return submit('get', payload, { options: {}, hooks })
    }

    const post = (payload = {}, hooks: hooks = {}) => {
        return submit('post', payload, { options: {}, hooks })
    }

    const put = (payload = {}, hooks: hooks = {}) => {
        return submit('put', payload, { options: {}, hooks })
    }

    const patch = (payload = {}, hooks: hooks = {}) => {
        return submit('patch', payload, { options: {}, hooks })
    }

    const _delete = (payload = {}, hooks: hooks = {}) => {
        return submit('delete', payload, { options: {}, hooks })
    }

    return {
        state: Object.assign({}, state, {
            isValidationError: state.statusCode === 422 && !state.busy && !_isObjEmpty(state.errors),
        }),
        setFormError: useCallback((field, value = null) => {
            if (field instanceof Object && typeof field === 'object') {
                setState(currentState =>
                    Object.assign({}, currentState, {
                        errors: Object.assign({}, currentState.errors, field),
                    })
                )
            }

            if (typeof field === 'string') {
                setState(currentState =>
                    Object.assign({}, currentState, {
                        errors: Object.assign({}, currentState.errors, { [field]: value }),
                    })
                )
            }
        }, []),
        clearFormError: useCallback(
            (...fields) => {
                if (!fields.length) {
                    setState(currentState => ({ ...currentState, errors: {} }))
                    return
                }

                let updatedErrors = { ...state.errors }

                fields.forEach(f => {
                    _hasProperty(updatedErrors, f) && delete updatedErrors[f]
                })

                setState(currentState => ({ ...currentState, errors: updatedErrors }))
            },
            [url]
        ),
        resetStates: useCallback(() => {
            setState({ ...initialState })
            aquarequest.resetStates()
        }, [url]),
        cancel: useCallback(() => {
            aquarequest.cancel()
            setState(currentState => ({ ...currentState, busy: false }))
        }, [url]),

        submit: useCallback((...args) => submit(...args), [url]),
        get: useCallback((...args) => get(...args), [url]),
        post: useCallback((...args) => post(...args), [url]),
        put: useCallback((...args) => put(...args), [url]),
        patch: useCallback((...args) => patch(...args), [url]),
        _delete: useCallback((...args) => _delete(...args), [url]),
    }
}
