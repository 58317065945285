import toast from 'react-hot-toast'
import { WarningCircle, X } from 'phosphor-react'

export const network = (code, message) =>
    toast(
        t => (
            <span className="inline-flex items-center">
                <b className="text-red-600">{code} !</b> <span className="ml-2">{message || 'Something went wrong'}</span>
                <button onClick={() => toast.dismiss(t.id)} className="ml-8 p-1.5 rounded-sm bg-gray-50 hover:bg-gray-100">
                    <X className="w-3 h-3 text-gray-700 hover:text-gray-800" weight="light" />
                </button>
            </span>
        ),
        {
            icon: <WarningCircle className="w-5 h-5 text-red-600" weight="fill" />,
        }
    )
