import { _hasProperty } from '@aw/utils'

const API_SUFFIX = process.env.NEXT_PUBLIC_BACKEND_API_URL_SUFFIX
const _P = process.env.NEXT_PUBLIC_API_MODE === 'test' ? '/api/mock' : ''

// 1st mock, 2nd live api
export const rewrites = {
    '/sanctum/csrf-cookie': ['/sanctum/csrf-cookie', '/sanctum/csrf-cookie'],
    '/login': ['/login', '/login'],
    '/login-with-google': ['/login-with-google', '/login-with-google'],
    '/register': ['/register', '/register'],
    '/logout': ['/logout', '/logout'],
    '/forgot-password': ['/forgot-password', '/forgot-password'],
    '/reset-password': ['/reset-password', '/reset-password'],
    '/email/verification-resend': ['/email/verification-resend', '/email/verification-resend'],
    '/email/verification-notification': ['/email/verification-notification', '/email/verification-notification'],

    '/has-twofactor-challenged': ['/has-twofactor-challenged', '/has-twofactor-challenged'],
    '/two-factor-challenge': ['/two-factor-challenge', '/two-factor-challenge'],

    '/user/confirmed-password-status': ['/user/confirmed-password-status', '/user/confirmed-password-status'],
    '/user/confirm-password': ['/user/confirm-password', '/user/confirm-password'],

    '/user/two-factor-authentication': ['/user/two-factor-authentication', '/user/two-factor-authentication'],
    '/user/confirmed-two-factor-authentication': ['/user/confirmed-two-factor-authentication', '/user/confirmed-two-factor-authentication'],
    '/user/two-factor-qr-code': ['/user/two-factor-qr-code', '/user/two-factor-qr-code'],
    '/user/two-factor-secret-key': ['/user/two-factor-secret-key', '/user/two-factor-secret-key'],
    '/user/two-factor-recovery-codes': ['/user/two-factor-recovery-codes', '/user/two-factor-recovery-codes'],
}

const resolve = url => {
    if (_hasProperty(rewrites, url)) {
        return process.env.NEXT_PUBLIC_API_MODE === 'test' ? _P + rewrites[url][0] : rewrites[url][1]
    }

    return process.env.NEXT_PUBLIC_API_MODE === 'test' ? _P + url : API_SUFFIX + url
}

export default resolve
