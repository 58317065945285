import clsx from 'clsx'
import { WarningCircle } from 'phosphor-react'
import useTheme from '../../aw-hooks/src/useTheme'
import React from "react"

const Input = ({
    disabled = false,
    error = false,
    left = null,
    right = null,
    className = '',
    ...props
}
    , ref
) => {

    const { theme } = useTheme()
    const defaultClasses =
        'block w-full focus:outline-none  placeholder:text-sm  placeholder:text-[#9498A2] p-2 rounded-[0.625rem] border border-neutralgray-4 px-4 text-sm text-neutralgray-9'
    const errorClasses = 'pr-10 border-red-300 text-red-900 placeholder-red-300  focus:ring-red-500 focus:border-red-500'

    const themeClass = ''

    return (
        <div className={clsx((left || right) && 'flex', 'rounded-[0.625rem] relative w-full', props.blur ? 'blur-sm' : 'blur-none')}
            style={{
                boxShadow: "0px 1px 2px 0px rgba(18, 20, 22, 0.05)"
            }}
        >
            {left}
            <input type='text' disabled={disabled} className={`${props.value ? '!bg-neutralgray-2' : 'bg-white'} ${className} ${clsx(error && errorClasses)} ${themeClass} ${defaultClasses}`} {...props} ref={ref} />
            {right}
            {error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <WarningCircle className="h-5 w-5 text-red-500" weight="fill" />
                </div>
            )}
        </div>
    )
}

export default React.forwardRef(Input)
