import React, { createContext, useState } from 'react';

interface AuthContextProps {
    show2FA: boolean;
    setShow2FA: (value: boolean) => void;
}

export const AuthContext = createContext<AuthContextProps>({
    show2FA: false,
    setShow2FA: () => { },
});

export const AuthProvider = ({ children }: { children: any }) => {
    const [show2FA, setShow2FA] = useState(false);

    const handleSetShow2FA = (value: boolean) => {
        setShow2FA(value);
    };

    return (
        <AuthContext.Provider value={{ show2FA, setShow2FA: handleSetShow2FA }}>
            {children}
        </AuthContext.Provider>
    );
};
