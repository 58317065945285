import React from 'react'
import options from './options.json'

export type theme = {
    color: string
    foreground: string
    background: string
}

export type themeName = keyof typeof options

export type mode = 'light' | 'dark' | 'system'

interface ThemeContextInterface {
    themeName: themeName
    theme: theme
    mode: mode
    switchTheme: (name: themeName) => void
    switchMode: (name: mode) => void
}

const DEFAULT_THEME_NAME: themeName = 'berry'
const DEFAULT_MODE: mode = 'light'

const ThemeContext = React.createContext<ThemeContextInterface>({
    themeName: DEFAULT_THEME_NAME,
    theme: options[DEFAULT_THEME_NAME],
    mode: DEFAULT_MODE,
    switchTheme: t => null,
    switchMode: t => null,
})

const ThemeProvider = ({ children }) => {
    const [themeName, setThemeName] = React.useState<themeName>(DEFAULT_THEME_NAME)
    const [theme, setTheme] = React.useState<theme>(options[themeName])
    const [mode, setMode] = React.useState<mode>(DEFAULT_MODE)

    const switchTheme = React.useCallback((name: themeName): void => {
        setThemeName(options[name] ? name : DEFAULT_THEME_NAME)
        setTheme(options[name] || options[DEFAULT_THEME_NAME])

        if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.setItem('theme', name)
        }
    }, [])

    const switchMode = React.useCallback((name: mode): void => {
        if (!['light', 'dark', 'system'].includes(name)) return
        setMode(name)

        if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.setItem('mode', name)
        }

        if (name === 'dark') document.documentElement.classList.add('dark')
        else document.documentElement.classList.remove('dark')
    }, [])

    return <ThemeContext.Provider value={{ theme, themeName, mode, switchTheme, switchMode }}>{children}</ThemeContext.Provider>
}

export { ThemeContext, ThemeProvider }
